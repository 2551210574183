<template>
  <div class="bloc-1">
    <div class="begin px-32 py-12 text-white">
      <div class="commencer flex items-center">
        <div class="texte w-1/2">
          <div class="message-text text-5xl font-bold leading-relaxed">
            {{ $t('accessLitterature') }}
          </div><br>
          <div class="icones flex items-center my-8">
            <div class="play mr-4">
              <a href="https://play.google.com/store/apps/details?id=com.craftman.rema&hl=fr" target="_blank">
                <svg-icon
                  name="ic_playstore-green"
                  original
                  class="w-40"
                />
              </a>
            </div>
            <div class="app">
              <a href="https://apps.apple.com/us/app/rema/id1540511460" target="_blank">
                <svg-icon
                  name="ic_appstore-green"
                  original
                  class="w-40"
                />
              </a>
            </div>
          </div>
          <div class="total flex items-center">
            <div class="globe mr-4">
              <svg-icon
                name="ic_globe"
                original
                class="w-16 "
              />
            </div>
            <div class="stat font-extrabold leading-normal">
              <div>{{ $t('oeuvreLitteraire') }}</div>
              <div>{{ $t('institutionalContributor') }}</div>
            </div>
          </div>
        </div>
        <div class="images w-1/2">
          <div class="image-plus relative">
            <img :src="imagePlus" class="float-right w-24" alt="image plus">
          </div>
          <div class="image-doc absolute">
            <img :src="imageRead" class="float-right" alt="image plus">
          </div>
          <div class="image-prise absolute">
            <img :src="imagePrise" class="float-right" alt="image plus">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import plus from '../../../assets/images/image_plus.webp'
import read from '../../../assets/images/image_read.webp'
import prise from '../../../assets/images/image_prise.webp'
export default {
  name: 'bloc-1',
  data () {
    return {
      imagePlus: plus,
      imageRead: read,
      imagePrise: prise
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .bloc-1 {
    background-color: $rema-green;
  }
  .commencer {
    height: 35rem;
  }
  .images {
    background-image: url("../../../assets/images/image_woman.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32rem;
    height: -webkit-fill-available;
  }
  .image-plus {
    top: -2rem;
    right: 10rem;
  }
  .image-doc {
    right: 0;
    top: 10rem;

  }
  .image-prise {
    top: 26rem;
    right: 25rem;
  }
  @media only screen and (max-width: 600px) {
    .bloc-1 {
      overflow: hidden;
    }
    .begin {
      padding-right: 1rem;
      padding-left: 1rem;
    }
    .commencer {
      display: block;
      height: 70rem;
    }
    .images {
      background-size: contain;
      background-position: top;
      margin-top: 5rem;
    }
    .texte, .images {
      width: 100%;
    }
    .message-text {
      text-align: center;
    }
    .image-plus {
      right: 0;
    }
    .image-prise {
      position: relative;
      top: 6rem;
      right: 4rem;
    }
    .image-doc {
      display: none;
    }
  }
</style>
