<template>
  <div class="home">
    <div class="part-1">
      <bloc-1/>
    </div>
    <div class="part-2">
      <bloc-2/>
    </div>
    <div class="part-3 py-12">
      <bloc-3/>
    </div>
  </div>
</template>

<script>
import Bloc2 from '../components/helper/home/bloc-2'
import Bloc3 from '../components/helper/home/bloc-3'
import Bloc1 from '../components/helper/home/bloc-1'
export default {
  name: 'Home',
  components: { Bloc1, Bloc3, Bloc2 }
}
</script>

<style lang="scss" scoped></style>
