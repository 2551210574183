<template>
  <div class="bloc-3">
    <div class="begin pl-32 py-10">
      <div class="is-content flex items-center">
        <div class="text w-1/2 pr-20">
          <div class="stay-connected font-bold text-5xl leading-relaxed">
            {{ $t('JoinCommunity') }}
          </div>
          <div class="icones flex items-center mt-8">
            <div class="playstore mr-4">
              <a href="https://play.google.com/store/apps/details?id=com.craftman.rema&hl=fr" target="_blank">
                <svg-icon
                  name="ic_playstore"
                  original
                  class="play w-40 cursor-pointer"
                />
              </a>
            </div>
            <div class="appstore mr-4">
              <a href="https://apps.apple.com/us/app/rema/id1540511460" target="_blank">
                <svg-icon
                  name="ic_appstore"
                  original
                  class="app w-40 cursor-pointer"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="content w-1/2">
          <div class="images-content">
            <div class="pic-1">
              <img
                :src="imagePeople"
                class="image-people"
                alt="image de doctor people"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="is-content-header flex items-center">
        <div class="content w-1/2">
          <div class="images-content">
            <div class="pic-1">
              <img
                :src="imagePeople"
                class="image-people"
                alt="image de doctor people"
              >
            </div>
          </div>
        </div>
        <div class="text w-1/2 pr-20">
          <div class="stay-connected font-bold text-5xl leading-relaxed">
            {{ $t('JoinCommunity') }}
          </div>
          <div class="icones flex items-center mt-8">
            <div class="playstore mr-4">
              <a href="https://play.google.com/store/apps/details?id=com.craftman.rema&hl=fr" target="_blank">
                <svg-icon
                  name="ic_playstore"
                  original
                  class="play w-40 cursor-pointer"
                />
              </a>
            </div>
            <div class="appstore mr-4">
              <a href="https://apps.apple.com/us/app/rema/id1540511460" target="_blank">
                <svg-icon
                  name="ic_appstore"
                  original
                  class="app w-40 cursor-pointer"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import people from '../../../assets/images/image_bloc2.webp'
export default {
  name: 'bloc-3',
  data () {
    return {
      imagePeople: people
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/style/sass/variables";
  .is-content-header {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .is-content-header {
      display: block;
    }
    .is-content {
      display: none !important;
    }
    .begin {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .is-content {
      display: block;
    }
    .content, .text {
      width: 100%;
    }
    .text {
      padding-left: 0;
    }
  }
</style>
